<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <div class="basic-table">
              <div
                class="row basic-table__header no-gutters align-items-center"
              >
                <div class="col-4">
                  <h3 class="basic-table__title text-seizered">
                    연체 집계
                  </h3>
                </div>
              </div>
              <div class="basic-table__table">
                <div class="row">
                  <div class="col-4">
                    <v-data-table
                      :headers="inputSumHeader"
                      :items="inputSumData"
                      class="seize-table-box"
                      dense
                      hide-default-footer
                      item-key="key"
                      loading-text="데이터 조회중입니다..."
                    >
                      <template v-slot:item.key="{ item }">
                        <span style="color: #000000; font-weight: bold;">{{
                          item.key
                        }}</span>
                      </template>
                      <template v-slot:item.count="{ item }">
                        <span style="color: #000000;">{{ item.count }}</span>
                      </template>
                    </v-data-table>
                  </div>
                  <div class="col-4">
                    <Chart
                      ref="overdueCountChart"
                      v-bind="{
                        options: countChartOptions,
                        plugins: chartPlugin,
                      }"
                    ></Chart>
                  </div>
                  <div class="col-4"></div>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <TransactionDataTable
              ref="overdueOutputTable"
              v-bind="{
                baseDate: baseDate,
                titleText: '연체 출금 상세',
                titleTextClass: 'text-seizered',
                detailData: detailOutputDataArray,
                filterDisabled: {
                  term: false,
                  inout: true,
                  targetInst: true,
                  name: true,
                  level2: true,
                  level3: true,
                  level4: true,
                  level5: true,
                  tranType: true,
                },
                inoutInitialFilter: ['출금'],
                itemsPerPage: 10,
              }"
            >
            </TransactionDataTable>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import * as d3 from "d3";
import numeral from "@/common/utils/numeral";
import SeizePlugin from "@/common/utils/seize";
import Chart from "@/views/partials/widgets/Chart.vue";
import Vue from "vue";
import ChartDataLabels from "chartjs-plugin-datalabels";
import TransactionDataTable from "@/views/partials/widgets/tables/TransactionDataTable";

Vue.use(SeizePlugin);
export default {
  name: "Overdue",
  components: {
    TransactionDataTable,
    KTPortlet,
    Chart,
  },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      tabIndex: 0,
      tabIndex2: 0,
      categoryMapData: {},
      detailOutputDataArray: this.getOutputData(),
      inputSumHeader: [
        {
          text: "기간",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "key",
        },
        {
          text: "건수",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "count",
        },
      ],
      inputSumData: [],
      countChartOptions: {},
      chartPlugin: [ChartDataLabels],
    };
  },
  mounted() {
    document.title = "연체";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.inputSumData = this.generateInputSumData();

    const countChartData = {
      labels: this.makeChartLabel(this.inputSumChartData),
      datasets: this.makeCountChartData(
        this.inputSumChartData,
        "건수",
        "rgb(246,78,96)"
      ),
    };
    this.countChartOptions = {
      type: "bar",
      data: countChartData,
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            anchor: "end",
            align: "end",
            color: "rgb(0,0,0)",
            font: {
              weight: "bold",
            },
            formatter: function (value) {
              return numeral(value).format("0,0") + "건";
            },
          },
        },
        title: {
          display: true,
        },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItems, data) {
              const value = data.datasets[0].data[tooltipItems.index];
              return numeral(value).format("0,0") + "건";
            },
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                fontStyle: "bold",
                fontColor: "rgb(246,78,96)",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              position: "left",
              gridLines: {
                display: true,
                color: "transparent",
                lineWidth: 0,
                zeroLineWidth: 3,
                zeroLineColor: "rgb(217,217,217)",
              },
              ticks: {
                display: false,
                beginAtZero: true,
                callback: function (value) {
                  return numeral(value).format("0,0");
                },
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.$refs.overdueOutputTable.setData(
        this.baseDate,
        this.getOutputData()
      );
      this.inputSumData = this.generateInputSumData();
      this.countChartOptions.data.labels = this.makeChartLabel(
        this.inputSumChartData
      );
      this.countChartOptions.data.datasets = this.makeCountChartData(
        this.inputSumChartData,
        "건수",
        "rgb(246,78,96)"
      );
      this.$refs.overdueCountChart.redrawChart();
    },
    generateInputSumData() {
      const categoryMap = d3
        .nest()
        .key(function (data) {
          return data.category_code1;
        })
        .map(this.$store.getters.requestItem.items);
      let categoryList = this.getOverdueCategoryData(categoryMap);
      const monthBasedData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                if (data.after_balance_amt === 0) {
                  return 1;
                } else if (
                  data.category_code1 === "WDFISPI01060000" ||
                  data.category_code1 === "WDFIBNK00590090" ||
                  data.category_code1 === "WDFIBNK00590133"
                ) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(categoryList);
      this.inputSumChartData = this.getSimpleResultValueOnly(monthBasedData);
      return this.getSimpleResult(monthBasedData);
    },
    getSimpleResult(values) {
      const result = [];
      const baseDate = this.baseDate;
      const before1M = Vue.getBeforeMonthString(baseDate, 1);
      const before2M = Vue.getBeforeMonthString(baseDate, 2);
      const before3M = Vue.getBeforeMonthString(baseDate, 3);
      let before1M_count = 0,
        before2M_count = 0,
        before3M_count = 0;
      values.forEach((element) => {
        if (element.key <= baseDate && element.key > before1M) {
          before1M_count += element.value.count;
        }
        if (element.key <= before1M && element.key > before2M) {
          before2M_count += element.value.count;
        }
        if (element.key <= before2M && element.key > before3M) {
          before3M_count += element.value.count;
        }
      });
      const b1MData = {
        key: "1M",
        count: numeral(before1M_count).format("0,0") + "건",
      };
      const b2MData = {
        key: "2M",
        count: numeral(before2M_count).format("0,0") + "건",
      };
      const b3MData = {
        key: "3M",
        count: numeral(before3M_count).format("0,0") + "건",
      };
      const sumData = {
        key: "합계",
        count:
          numeral(before1M_count + before2M_count + before3M_count).format(
            "0,0"
          ) + "건",
      };
      const avgData = {
        key: "평균",
        count:
          numeral(
            Math.round((before1M_count + before2M_count + before3M_count) / 3)
          ).format("0,0") + "건",
      };
      result.push(b1MData);
      result.push(b2MData);
      result.push(b3MData);
      result.push(sumData);
      result.push(avgData);
      return result;
    },
    getOverdueCategoryData(categoryMap) {
      let result = [];
      const codeList = [
        "WDLFUTB00340012",
        "WDLFUTB00340082",
        "WDLFUTB00340000",
        "WDLFUTB00610000",
        "WDLFUTB01180000",
        "WDLFUTB01760012",
        "WDLFUTB01760016",
        "WDLFUTB01760082",
        "WDLFUTB01760000",
        "WDLFUTB01910000",
        "WDFIMAD00510000",
        "WDFIMAD00590033",
        "WDFIMAD00590065",
        "WDFIMNL00590001",
        "WDFIMNL00590041",
        "WDFIMNL00590043",
        "WDFIMNL00770015",
        "WDLFRNT00000000",
        "WDFIINS00590049",
        "WDFIINS00590087",
        "WDFIINS00590000",
        "WDFIINS00770000",
        "WDFIINS00880000",
        "WDFIINS00880089",
        "WDFIINS00880101",
        "WDFIBNK00590047",
        "WDFIBNK00590049",
        "WDFIBNK00590080",
        "WDFIBNK00590090",
        "WDFIBNK00590097",
        "WDFIBNK00590133",
        "WDFIBNK00590000",
        "WDFISVB00000000",
        "WDFICAD00590105",
        "WDFICAD00590136",
        "WDFICAD01280000",
        "WDFICAD02410000",
        "WDFICPT00000000",
        "WDLFTEL00720037",
        "WDLFTEL00720052",
        "WDLFTEL00720057",
        "WDLFTEL00720063",
        "WDLFTEL00720064",
        "WDLFTEL00720085",
        "WDLFTEL01650063",
        "WDLFTEL01650038",
        "WDLFTEL01740063",
        "WDLFTEL01740064",
        "WDFISPI00140000",
        "WDFISPI00430000",
        "WDFISPI00540000",
        "WDFISPI00850000",
        "WDFISPI01000000",
        "WDFISPI01030000",
        "WDFISPI01050000",
        "WDFISPI01250000",
        "WDFISPI01260000",
        "WDFISPI01270000",
        "WDFISPI01290000",
        "WDFISPI01490000",
        "WDFISPI02060000",
        "WDFISPI02070000",
        "WDFISPI02520000",
        "WDFISPI02530000",
        "WDFISPI02540000",
        "WDFISPI01060000",
      ];
      codeList.forEach((element) => {
        if (categoryMap.get(element))
          result = result.concat(categoryMap.get(element));
      });
      return result;
    },
    getSimpleResultValueOnly(values) {
      const result = [];
      const baseDate = this.baseDate;
      const before1M = Vue.getBeforeMonthString(baseDate, 1);
      const before2M = Vue.getBeforeMonthString(baseDate, 2);
      const before3M = Vue.getBeforeMonthString(baseDate, 3);
      let before1M_count = 0,
        before2M_count = 0,
        before3M_count = 0;
      values.forEach((element) => {
        if (element.key <= baseDate && element.key > before1M) {
          before1M_count += element.value.count;
        }
        if (element.key <= before1M && element.key > before2M) {
          before2M_count += element.value.count;
        }
        if (element.key <= before2M && element.key > before3M) {
          before3M_count += element.value.count;
        }
      });
      const b1MData = {
        key: "1M",
        count: before1M_count,
      };
      const b2MData = {
        key: "2M",
        count: before2M_count,
      };
      const b3MData = {
        key: "3M",
        count: before3M_count,
      };
      const avgData = {
        key: "평균",
        count: Math.round(
          (before1M_count + before2M_count + before3M_count) / 3,
          2
        ),
      };
      result.push(b1MData);
      result.push(b2MData);
      result.push(b3MData);
      result.push(avgData);
      return result;
    },
    makeChartLabel(rawData) {
      const result = [];
      rawData.forEach((element) => {
        result.push(element.key);
      });
      return result;
    },
    makeCountChartData(rawData, label, color) {
      const result = [];
      const dataSets = {
        label: label,
        backgroundColor: color,
        barThickness: 50,
        maxBarThickness: 55,
      };
      const data = [];
      rawData.forEach((element) => {
        data.push(element.count);
      });
      dataSets.data = data;
      result.push(dataSets);
      return result;
    },
    getOutputData() {
      this.categoryMapData = d3
        .nest()
        .key(function (data) {
          return data.category_code1;
        })
        .map(this.$store.getters.requestItem.items);
      return this.getOutputCategoryData(this.categoryMapData);
    },
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    getOutputCategoryData(categoryMap) {
      let result = [];
      const codeList = [
        "WDLFUTB00340012",
        "WDLFUTB00340082",
        "WDLFUTB00340000",
        "WDLFUTB00610000",
        "WDLFUTB01180000",
        "WDLFUTB01760012",
        "WDLFUTB01760016",
        "WDLFUTB01760082",
        "WDLFUTB01760000",
        "WDLFUTB01910000",
        "WDFIMAD00510000",
        "WDFIMAD00590033",
        "WDFIMAD00590065",
        "WDFIMNL00590001",
        "WDFIMNL00590041",
        "WDFIMNL00590043",
        "WDFIMNL00770015",
        "WDLFRNT00000000",
        "WDFIINS00590049",
        "WDFIINS00590087",
        "WDFIINS00590000",
        "WDFIINS00770000",
        "WDFIINS00880000",
        "WDFIINS00880089",
        "WDFIINS00880101",
        "WDFIBNK00590047",
        "WDFIBNK00590049",
        "WDFIBNK00590080",
        "WDFIBNK00590090",
        "WDFIBNK00590097",
        "WDFIBNK00590133",
        "WDFIBNK00590000",
        "WDFISVB00000000",
        "WDFICAD00590105",
        "WDFICAD00590136",
        "WDFICAD01280000",
        "WDFICAD02410000",
        "WDFICPT00000000",
        "WDLFTEL00720037",
        "WDLFTEL00720052",
        "WDLFTEL00720057",
        "WDLFTEL00720063",
        "WDLFTEL00720064",
        "WDLFTEL00720085",
        "WDLFTEL01650063",
        "WDLFTEL01650038",
        "WDLFTEL01740063",
        "WDLFTEL01740064",
        "WDFISPI00140000",
        "WDFISPI00430000",
        "WDFISPI00540000",
        "WDFISPI00850000",
        "WDFISPI01000000",
        "WDFISPI01030000",
        "WDFISPI01050000",
        "WDFISPI01060000",
        "WDFISPI01250000",
        "WDFISPI01260000",
        "WDFISPI01270000",
        "WDFISPI01290000",
        "WDFISPI01490000",
        "WDFISPI02060000",
        "WDFISPI02070000",
        "WDFISPI02520000",
        "WDFISPI02530000",
        "WDFISPI02540000",
      ];
      const non0CodeList = [
        "WDFIBNK00590090",
        "WDFIBNK00590133",
        "WDFISPI01060000",
      ];
      codeList.forEach((element) => {
        if (categoryMap.get(element))
          if (non0CodeList.includes(element)) {
            categoryMap.get(element).forEach((item) => {
              result.push(item);
            });
          } else {
            categoryMap.get(element).forEach((item) => {
              if (item.after_balance_amt === 0) {
                result.push(item);
              }
            });
          }
      });
      return result;
    },
  },
};
</script>
